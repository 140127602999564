import { all, fork } from "redux-saga/effects";

//public
import LayoutSaga from "./layout/saga";
import eTokensSaga from "./eTokens/saga";
import risksSaga from "./risks/saga";
import pasSaga from "./premiumsAccounts/saga";
import userSaga from "./user/saga";
import settingsSaga from "./settings/saga";

import {
  getEncodedCall,
  getContract,
  getAbiName,
  getABI,
  getFormatter,
  getSignerContract,
  getTxReceiptStatus,
} from "../contractRegistry";
import { ethereumSaga, initializeEthereumStore } from "@ensuro/ethereum-store/src/package-index";
import { apiSaga, initializeAPIStore } from "@ensuro/api-calls-store/src/package-index";
import { selectChainId, selectProvider, selectUserAddress } from "./user/selectors";
import { getAPI } from "../apiRegistry";
import { api, chain, ethereum } from "../config";

initializeEthereumStore({
  getEncodedCall,
  getContract,
  getAbiName,
  getABI,
  getFormatter,
  getSignerContract,
  getTxReceiptStatus,
  selectUserAddress,
  selectChainId,
  selectProvider,
  chain,
  clockCount: ethereum.clockCount || 10,
});

initializeAPIStore({ getAPI, clockCount: api.clockCount });

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(eTokensSaga),
    fork(risksSaga),
    fork(pasSaga),
    fork(userSaga),
    fork(ethereumSaga),
    fork(apiSaga),
    fork(settingsSaga),
  ]);
}
